<template>
    <Dialog
        v-model:visible="filterVisibility"
        :draggable="false"
        :modal="true"
        dismissableMask
        position="right"
        class="advanced-search-filter"
    >
        <div class="header">
            <h2 class="title">{{ $t('advSearch.filter.title') }}</h2>
            <i class="icomoon icon-close" @click="filterVisibility = false"></i>
        </div>
        <div class="body">
            <CountryFilter v-model:selectedItems="parentCountry" />
            <hr class="h-line" />
            <CategoryFilter v-model:selectedItems="parentCategory" :tempCountries="parentCountry" />
            <hr class="h-line" v-if="category === 'university'" />
            <SubjectsFilter v-if="category === 'university'" v-model:selectedItems="parentSubjects" />
            <hr class="h-line" v-if="category === 'university'" />
            <IntakeDateFilter v-if="category === 'university'" v-model:intakeDates="parentIntakeDates" />
            <hr class="h-line" />
            <LevelFilter v-model:levels="parentLevels" />
            <hr class="h-line" />
            <PriceRangeFilter v-model:selectedItems="parentPriceRange" />
            <hr class="h-line" v-if="category === 'university'" />
            <AppFeesFilter v-if="category === 'university'" v-model:checked="parentAppFeesChecked" />
        </div>
        <div class="footer">
            <div class="footer-container">
                <button class="clear-btn" @click="clear">
                    {{ $t('clear') }}
                </button>
                <button
                    class="submit-btn"
                    @click="submit"
                    :disabled="checkFilters"
                    :class="{ 'disabled-btn': checkFilters }"
                >
                    {{ $t('Showresults') }}
                </button>
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { useCommonStore } from '../../../../store/search/common';
import CountryFilter from './CountryFilter.vue';
import IntakeDateFilter from './IntakeDateFilter.vue';
import LevelFilter from './LevelFilter.vue';
import SubjectsFilter from './SubjectsFilter.vue';
import CategoryFilter from './CategoryFilter.vue';
import { useCheckCategory } from '../../../../composables/useCheckCategory';
import PriceRangeFilter from './PriceRangeFilter.vue';
import AppFeesFilter from './AppFeesFilter.vue';

const props = defineProps({
    icon: {
        type: String,
        required: false,
        default: '',
    },
});

const commonStore = useCommonStore();
const { filterVisibility, category } = storeToRefs(commonStore);
const {
    categoryFilterIntakeDates,
    categoryFilterClear,
    categoryFilterLevels,
    getCategoryList,
    getCategoryCourses,
    categoryFilterSubjects,
    categoryFilterCountries,
    categoryFilterUniversitiesOrInstitutes,
    categoryFilterPriceRange,
    categoryFilterAppFees,
} = useCheckCategory();

const parentCountry = ref([]);
const parentCategory = ref([]);
const parentSubjects = ref([]);
const parentIntakeDates = ref([]);
const parentLevels = ref([]);
const parentPriceRange = ref([]);
const parentAppFeesChecked = ref([]);

const submit = _ => {
    // clear all filters
    categoryFilterClear.value();

    // add new filters
    categoryFilterCountries.value.push(...parentCountry.value);
    categoryFilterUniversitiesOrInstitutes.value.push(...parentCategory.value);
    categoryFilterSubjects.value.push(...parentSubjects.value);
    categoryFilterIntakeDates.value.push(...parentIntakeDates.value);
    categoryFilterLevels.value.push(...parentLevels.value);
    categoryFilterPriceRange.value.push(...parentPriceRange.value);
    categoryFilterAppFees.value.push(...parentAppFeesChecked.value);

    // call APIs
    getCategoryList.value();
    getCategoryCourses.value();

    // close filter dialog
    filterVisibility.value = false;
};

const checkFilters = computed(() => {
    return (
        !parentCountry.value.length &&
        !parentCategory.value.length &&
        !parentSubjects.value.length &&
        !parentIntakeDates.value.length &&
        !parentLevels.value.length &&
        !parentAppFeesChecked.value.length &&
        (!parentPriceRange.value.length ||
            parentPriceRange.value[0]?.priceRange[0] > parentPriceRange.value[0]?.priceRange[1])
    );
});

const clear = _ => {
    // clear all filters
    categoryFilterClear.value();

    // call APIs
    getCategoryList.value();
    getCategoryCourses.value();

    // close filter dialog
    filterVisibility.value = false;
};
</script>

<style lang="scss">
@import 'libs/shared/assets/scss/main.scss';
.p-dialog-mask.p-component-overlay.p-dialog-right {
    .p-dialog.advanced-search-filter {
        transform: none;
        width: 100%;
        margin: 0;
        background-color: white;
        display: flex;
        height: 100%;
        border-radius: 0;
        box-shadow: -8px 0px 8px 0px #a09c9c1a;
        max-height: 100%;

        .p-dialog-header {
            display: none;
        }

        .p-dialog-content {
            padding: 0;
            height: 100%;
            display: flex;
            flex-direction: column;

            .header {
                padding: 1.5rem 2rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $lavender-1;
                .title {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
                i {
                    cursor: pointer;
                    font-size: toRem(18);
                }
            }
            .body {
                padding: 1.5rem 2rem;
                display: flex;
                flex-direction: column;
                gap: toRem(24);
                .h-line {
                    border-color: $lavender-1;
                    opacity: 1;
                    margin: 0;
                }
            }
            .footer {
                margin-top: auto;
                padding: 1.5rem;
                box-shadow: 0px -2px 8.5px 0px rgba(162, 162, 162, 0.14);
                .footer-container {
                    display: flex;
                    justify-content: center;
                    gap: 1.75rem;
                    .clear-btn {
                        font-size: 1.125rem;
                        color: $primary-color;
                        background-color: transparent;
                        outline: none;
                        border: none;
                    }
                    .submit-btn {
                        font-size: 1.125rem;
                        background-color: $primary-color;
                        color: white;
                        border-radius: toRem(10);
                        padding: 10px 1.5rem;
                        outline: none;
                        border: none;
                        &.disabled-btn {
                            background-color: rgba(31, 41, 156, 0.5);
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
@include mediaMin($md) {
    .p-dialog-mask.p-component-overlay.p-dialog-right {
        .p-dialog.advanced-search-filter {
            width: 70%;
        }
    }
}

@include mediaMin($xl) {
    .p-dialog-mask.p-component-overlay.p-dialog-right {
        align-items: center;

        .p-dialog.advanced-search-filter {
            width: 33%;
        }
    }
}
html[dir='rtl'] {
    .p-dialog-mask.p-component-overlay.p-dialog-right {
        .p-dialog.advanced-search-filter {
            box-shadow: 8px 0px 8px 0px #a09c9c1a;
        }
    }
}
html[dir='rtl'] {
    .p-component {
        font-family: 'Cairo', sans-serif;
    }
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
    text-align: center;
}

.p-autocomplete.p-component {
    .p-autocomplete-input.p-inputtext.p-component {
        padding: 10px 12px;
    }

    .p-button.p-component {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 10px 12px;

        svg {
            color: #0000008a;
        }
    }
}
.p-autocomplete-panel.p-component {
    z-index: 9999999999 !important;
}
</style>
